import { CaseStudyHero } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { marginContainer } from './CaseStudyHero.module.css';

export default function CaseStudyHeroModule({
  className,
  data
}) {
  const {
    hasMarginBottom,
    heroImage,
    imageAlt,
    logo,
    logoAlt,
    stat1Label,
    stat1Value,
    stat2Label,
    stat2Value,
    stat3Label,
    stat3Value,
    stat4Label,
    stat4Value,
    title
  } = data;

  const stats = [];

  if (stat1Label) {
    stats.push({ label : stat1Label, value : stat1Value });
  }
  if (stat2Label) {
    stats.push({ label : stat2Label, value : stat2Value });
  }
  if (stat3Label) {
    stats.push({ label : stat3Label, value : stat3Value });
  }
  if (stat4Label) {
    stats.push({ label : stat4Label, value : stat4Value });
  }

  const classes = classNames(className, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <CaseStudyHero
      className={classes}
      heroSrcFluid={heroImage.fluid}
      imageAlt={imageAlt}
      logoAlt={logoAlt}
      logoSrcFluid={logo.fluid}
      stats={stats}
      title={title}
    />
  );
}

export const CaseStudyHeroDataPropsObject = {
  articlePreviewBody                 : PropTypes.string,
  articlePreviewCategory             : PropTypes.string,
  articlePreviewCategoryUrl          : PropTypes.string,
  articlePreviewCtaLinkTrackingClass : PropTypes.string,
  articlePreviewImageSrcFluid        : PropTypes.shape({
    aspectRatio : PropTypes.number,
    sizes       : PropTypes.string,
    src         : PropTypes.string,
    srcSet      : PropTypes.string,
    srcSetWebp  : PropTypes.string,
    srcWebp     : PropTypes.string
  }).isRequired,
  articlePreviewPostUrl : PropTypes.string.isRequired,
  articlePreviewTitle   : PropTypes.string.isRequired,
  hasMarginBottom       : PropTypes.bool,
  heroImage             : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }).isRequired,
  imageAlt : PropTypes.string,
  logo     : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }).isRequired,
  logoAlt    : PropTypes.string,
  stat1Label : PropTypes.string.isRequired,
  stat1Value : PropTypes.string.isRequired,
  stat2Label : PropTypes.string.isRequired,
  stat2Value : PropTypes.string.isRequired,
  stat3Label : PropTypes.string,
  stat3Value : PropTypes.string,
  stat4Label : PropTypes.string,
  stat4Value : PropTypes.string,
  title      : PropTypes.string.isRequired
};

CaseStudyHeroModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(CaseStudyHeroDataPropsObject).isRequired
};

CaseStudyHeroModule.defaultProps = {
  className : null
};

export const CaseStudyHeroModuleData = graphql`
fragment CaseStudyHeroModuleData on ContentfulComponentCaseStudyHero {
    articlePreviewBody
    articlePreviewCategory
    articlePreviewCategoryUrl
    articlePreviewCtaLinkTrackingClass
    articlePreviewImageSrcFluid {
      fluid(maxWidth: 270) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    articlePreviewPostUrl
    articlePreviewTitle
    hasMarginBottom
    heroImage {
      fluid(quality: 90, maxWidth: 1600) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    imageAlt
    logo {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    stat1Label
    stat1Value
    stat2Label
    stat2Value
    stat3Label
    stat3Value
    stat4Label
    stat4Value
    title
}
`;
